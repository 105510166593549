import React, { useMemo } from "react";
import * as FaIcons from "react-icons/fa";
import "./Team.css";
import { useContent } from "../../../context/ContentProvider";

export default function Team() {
  const { data: assets } = useContent("assets");
  const { data: teamMembers } = useContent("team");

  const groupedTeamMembers = useMemo(() => {
    if (!teamMembers) {
      return null;
    }
    return teamMembers.reduce((groupedMembers, member) => {
      (groupedMembers[member.attributes.position] = groupedMembers[member.attributes.position] || []).push(member);
      return groupedMembers;
    }, {});
  }, [teamMembers]);

  return (
    teamMembers && (
      <div className="container my-5">
        {assets?.Teambild?.data && (
          <div className="mb-5 d-flex justify-content-center">
            <img
              alt={assets.Teambild?.data?.attributes?.name}
              src={process.env.REACT_APP_BASE_URL + assets.Teambild?.data?.attributes?.url}
              className="rounded-3 img-fluid"
            />
          </div>
        )}

        {Object.keys(groupedTeamMembers).map((position) => (
          <div className="mb-5" key={position}>
            <h2 className="mb-4">{position}</h2>
            {groupedTeamMembers[position].map((member) => (
              <div className="row mb-4" key={member.id}>
                <div className="col col-6 col-lg-4 text-break d-flex">
                  {member.attributes.image.data ? (
                    <img
                      className={"me-4"}
                      height={"160px"}
                      width={"120px"}
                      src={process.env.REACT_APP_BASE_URL + member.attributes.image.data.attributes.url}
                      alt={member.attributes.name}
                    />
                  ) : (
                    ""
                  )}
                  <div>
                    <div className="lead fw-bold">{member.attributes.name}</div>
                    <div>{member.attributes.role}</div>
                  </div>
                </div>
                <div className="col col-6 col-lg-8 text-break">
                  <div className="row">
                    <div className="col col-12 col-lg-6 pb-1">
                      {member.attributes.email ? (
                        <>
                          <FaIcons.FaEnvelope className="me-2 fs-5" />
                          <a className="text-decoration-none" href={`mailto:${member.attributes.email}`}>
                            {member.attributes.email}
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col col-12 col-lg-6">
                      {member.attributes.sprechstunde ? (
                        <span title="Sprechstunde">
                          <FaIcons.FaClock className="me-2 fs-5" />
                          {member.attributes.sprechstunde}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  );
}
