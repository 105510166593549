import {useState} from 'react';

function useAPI() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [response, setResponse] = useState(null);

    const sendRequest = async (endpoint, method = 'GET', params = {}, body = null) => {
        setLoading(true);
        setError(null);
        setStatus(null);
        setResponse(null);

        const url = new URL(`${process.env.REACT_APP_BASE_URL}/api/${endpoint}`);
        const queryParams = new URLSearchParams(params);

        if (Object.keys(params).length > 0) {
            url.search = queryParams.toString();
        }

        // prepare the headers
        const headers = new Headers();
        headers.set('Content-Type', 'application/json');

        try {
            const requestOptions = {
                method,
                headers: headers,
                body: body ? JSON.stringify(body) : null,
            };
            const res = await fetch(url.toString(), requestOptions);
            const data = await res.json();

            if (!res.ok) {  // Check if the response status is not in the 2xx range
                throw new Error(`Server responded with a status of ${res.status}: ${data.error || res.statusText}`);
            }

            setLoading(false);
            setStatus(res.status);
            setResponse(data);

            return data;
        } catch (err) {
            setLoading(false);

            if (err instanceof TypeError && err.message === 'Failed to fetch') {
                // Network or CORS error
                setError(new Error('Network error: Failed to fetch'));
            } else if (err.response) {
                // Server responded with a status outside the 2xx range
                setError(new Error(`Server error: ${err.response.status} - ${err.response.statusText}`));
            } else {
                // Any other error (e.g., thrown by `fetch`, parsing error, or a manual throw statement)
                setError(err);
            }
        }

    };


    return {loading, error, status, response, sendRequest};
}

export default useAPI;