import {useContent} from "../../../context/ContentProvider";
import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import {MDLinkRenderer} from "../../../utils/utils";

const Buffet = () => {
    const {data} = useContent("buffet");

    const [imageIndex, setImageIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsTransitioning(true);
            setTimeout(() => {
                setImageIndex(prevIndex => (prevIndex + 1) % data.Bilder.data.length);
                setIsTransitioning(false);
            }, 1000);  // has to be similar to the transition duration
        }, 10000);
        return () => clearInterval(intervalId);
    }, [data]);

    return data && (
        <div className={"bg-white"}>
            <div className="pt-5 partnerschool-section d-flex align-items-center">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5 mb-5 mb-lg-0">
                            <div className="section-title position-relative mb-4">
                                <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                                    {data.Titel1}
                                </h6>
                                <h1 className="display-4 fw-bold">
                                    {data.Titel2}
                                </h1>
                            </div>
                            <ReactMarkdown components={{a: MDLinkRenderer}} className="m-0 text-break" children={data.Text}/>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-center align-items-center">
                            <div className={`image-container ${isTransitioning ? 'transitioning' : ''}`}>
                                <img
                                    alt={"clara-fey-schule"}
                                    src={process.env.REACT_APP_BASE_URL + data.Bilder.data[imageIndex].attributes.url}
                                    className={"w-100 shadow1 rounded-1"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Buffet;