import {useEffect, useState} from "react";
import ReactMarkdown from 'react-markdown';
import {formatDate} from "../../../utils/date";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {MDLinkRenderer} from "../../../utils/utils";

export default function Events() {
    return (
        <div className={"my-5"}>
            <Entries/>
        </div>
    )
}

const Entries = () => {
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 8;

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/events?populate=*&sort=date:DESC&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}`)
            .then(response => response.json())
            .then(data => {
                if (data.data) setEvents(data.data);
                if (data.meta) {
                    setTotalPages(data.meta.pagination.pageCount);
                }

                // Scroll to top of page
                window.scrollTo(0, 0);
            })
            .catch(error => console.error('Error:', error));
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            {events.map((event, index) => (
                <div className="container my-5 py-2 py-md-4" key={event.id}>
                    <div className="row">
                        <div
                            className={`col-12 col-md-6 d-flex align-items-center order-0 order-md-${(index % 2) === 0 ? 0 : 1}`}>
                            <div className={"mw-100"}>
                                <div className="section-title position-relative mb-4">
                                    {event.attributes.date && (
                                        <h6 className="d-inline-block position-relative text-secondary pb-1">
                                            {formatDate(new Date(event.attributes.date))}
                                        </h6>
                                    )}
                                    <h1 className="display-5 fw-bold text-break ">{event.attributes.title}</h1>
                                </div>
                                <ReactMarkdown
                                    components={{a: MDLinkRenderer}}
                                    className={"text-break"}
                                    children={event.attributes.description}
                                />
                            </div>
                        </div>
                        {event.attributes.images.data &&
                            <div className={"col-12 col-md-6 d-flex align-items-center"}>
                                <div id={`eventCarouselIndicators-${event.id}`} className="carousel slide w-100">
                                    {event.attributes.images.data?.length > 1 &&
                                        <div className="carousel-indicators">
                                            {event.attributes.images.data?.map((image, idx) => (
                                                <button
                                                    key={idx}
                                                    type="button"
                                                    data-bs-target={`#eventCarouselIndicators-${event.id}`}
                                                    data-bs-slide-to={idx}
                                                    className={idx === 0 ? 'active' : ''}
                                                    aria-label={`Slide ${idx + 1}`}
                                                ></button>
                                            ))}
                                        </div>}
                                    <div className="carousel-inner d-flex align-items-center">
                                        {event.attributes.images.data?.map((image, idx) => {
                                            return (
                                                <div key={idx}
                                                     className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
                                                    <img src={process.env.REACT_APP_BASE_URL + image.attributes.url}
                                                         className="d-block w-100"
                                                         alt="Projektbild"/>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {event.attributes.images.data?.length > 1 && <>
                                        <button className="carousel-control-prev" type="button"
                                                data-bs-target={`#eventCarouselIndicators-${event.id}`}
                                                data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button"
                                                data-bs-target={`#eventCarouselIndicators-${event.id}`}
                                                data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            ))};

            <div className={"container"}>
                <nav aria-label="Page navigation" className={"d-flex justify-content-center"}>
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}>
                                <FaChevronLeft/>
                            </button>
                        </li>
                        {[...Array(totalPages).keys()].map(page => (
                            <li key={page + 1} className={`page-item ${page + 1 === currentPage ? 'active' : ''}`}>
                                <button className="page-link"
                                        onClick={() => handlePageChange(page + 1)}>{page + 1}</button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}>
                                <FaChevronRight/>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}