import "./Carousel.css";
import {useEffect, useState} from "react";
import {useContent} from "../../../context/ContentProvider";

let activeIndex = 0;

export default function Classes() {
    const {data: classes} = useContent("classes")

    const [translateX, setTranslateX] = useState(0);

    function setNewTranslateX() {
        let imageWidth;
        const screenWidth = window.innerWidth;

        if (screenWidth > 991) {
            imageWidth = 700;
        } else if (screenWidth > 767) {
            imageWidth = 500;
        } else {
            imageWidth = 300;
        }

        const centerOffset = (screenWidth - imageWidth) / 2;
        const newTranslateX = -activeIndex * imageWidth + centerOffset;
        setTranslateX(newTranslateX);


        // blur all images except active one
        document.querySelectorAll(".cc-images > div").forEach((image) => {
            if (image.id === `cc-image-${activeIndex}`) {
                image.classList.remove("blur");
            } else {
                image.classList.add("blur");
            }
        });
    }


    const handleClick = (index) => {
        activeIndex = index;

        setNewTranslateX();

        document.querySelectorAll(".cc-btns button").forEach((button) => {
            button.classList.remove("active");
        });

        document.querySelector(`#cc-btn-${activeIndex}`).classList.add("active");
    };

    const handleResize = () => {
        setNewTranslateX();
    }

    // Center the first image on initial render
    useEffect(() => {
        let imageWidth;
        const screenWidth = window.innerWidth;

        if (screenWidth > 991) {
            imageWidth = 700;
        } else if (screenWidth > 767) {
            imageWidth = 600;
        } else {
            imageWidth = 400;
        }

        const centerOffset = (screenWidth - imageWidth) / 2;
        setTranslateX(centerOffset);

        window.addEventListener('resize', handleResize);
        setNewTranslateX();
    }, [classes]);


    return classes && (
        <div>
            <div className="mt-4">
                <div className="container lead text-center cc-btns px-0">
                    {
                        classes.map((classEl, index) => {
                            return <button key={`cc-btn-${index}`} id={`cc-btn-${index}`}
                                           className={`btn btn-light px-3 px-sm-4 py-3 ${index === 0 ? 'active' : ''}`}
                                           onClick={() => handleClick(index)}>{classEl.attributes.Klasse}</button>
                        })
                    }
                </div>
                <div className="overflow-hidden py-5">
                    <div
                        className="d-flex cc-images"
                        style={{
                            transform: `translateX(${translateX}px)`
                        }}
                    >
                        {classes.map((classEl, index) => (
                            <div id={`cc-image-${index}`} key={`cc-image-${index}`}>
                                <img
                                    src={
                                        classEl.attributes.Klassenfoto?.data ?
                                            process.env.REACT_APP_BASE_URL + `${classEl.attributes.Klassenfoto?.data?.attributes.url}`
                                            : "/img/no-image-placeholder.png"
                                    }
                                    alt={classEl.attributes.Klasse}/>
                                <div className="row mt-3 class-info">
                                    <div className="col px-2 text-end fw-light">
                                        <p className="my-1">Klasse:</p>
                                        <p className="my-1">Klassenvorstand:</p>
                                        <p className="my-1">Klassensprecher:</p>
                                    </div>
                                    <div className="col px-2">
                                        <p className="my-1">{classEl?.attributes?.Klasse}</p>
                                        <p className="my-1">{classEl?.attributes?.Klassenvorstand}</p>
                                        <p className="my-1">{classEl?.attributes?.Klassensprecher}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
