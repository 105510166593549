import {FaAngleRight, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaSchool} from "react-icons/fa";
import {HiOutlineCodeBracket} from "react-icons/hi2";
import {Link} from "react-router-dom";
import {useContent} from "../../context/ContentProvider";

const Footer = () => {
    const {data} = useContent("contact");

    return data && (
        <div className={"pt-10"}>
            <div className="bg-dark text-white-50 pt-5"
                 style={{
                     backgroundImage: `url(/img/overlay-top.png)`,
                     backgroundRepeat: "repeat-x",
                     zIndex: 1
                 }}>
                <div className="container mt-5 pt-5">
                    <div className="d-flex flex-column flex-md-row justify-content-around pb-5">
                        <div className="d-flex justify-content-md-center justify-content-md-start mx-4 mb-5 mb-md-0">
                            <div>
                                <h3 className="text-white mb-4">Kontakt</h3>
                                <p>
                                    <FaMapMarkerAlt/>
                                    <a href={data?.Adresse?.Link}
                                       target={"_blank"} className={"ms-2 text-white-50"}>
                                        {data?.Adresse.Adresse}
                                    </a>
                                </p>
                                <p>
                                    <FaPhoneAlt/>
                                    <a href={`tel:${data?.Telefonnummer}`} target={"_blank"}
                                       className={"ms-2 text-white-50"}>{data?.Telefonnummer}</a>
                                </p>
                                <p>
                                    <FaEnvelope/>
                                    <a href={`mailto:${data?.Email}`} target={"_blank"}
                                       className={"ms-2 text-white-50"}>
                                        {data?.Email}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-md-center justify-content-start mx-4">
                            <div>
                                <h3 className="text-white mb-4">Andere Links</h3>
                                <div className="d-flex flex-column gap-3">
                                    <Link className="text-white-50" to="/team"><FaAngleRight/>
                                        <span className={"ms-1"}>Team</span>
                                    </Link>
                                    <Link className="text-white-50" to="/contact#imprint"><FaAngleRight/>
                                        <span className={"ms-1"}>Impressum</span>
                                    </Link>
                                    <Link className="text-white-50" to="/contact"><FaAngleRight/>
                                        <span className={"ms-1"}>Kontakt</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-white-50 border-top py-3"
                 style={{borderColor: "rgba(256, 256, 256, .1) !important"}}>
                <div className="container">
                    <div className="d-flex flex-column align-items-center">
                        <div className="mb-3 mb-md-0">
                            <a className="text-white" href="/"><FaSchool size={"1.1em"} className={"me-2"}/>NMS
                                Hausleiten</a>
                        </div>
                        <div>
                            <a
                                className="text-white"
                                target={"_blank"}
                                href="mailto:nms@jonastuechler.at"
                                style={{fontSize: "0.75em"}}
                            >
                                <HiOutlineCodeBracket size={"1.1em"} className={"me-2 mb-0"}/>Jonas Tüchler
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;