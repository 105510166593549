import * as FaIcons from 'react-icons/fa';

import "./Contact.css";
import ReactMarkdown from "react-markdown";
import {useContent} from "../../context/ContentProvider";
import {MDLinkRenderer} from "../../utils/utils";

export default function Contact({noImprint = false}) {
    const {data} = useContent("contact");

    return data && (
        <div className={"container my-5"}>
            <Links data={data}/>
            <div className="row">
                {data?.Bilder?.data &&
                    <div className={"col-12 col-md-6 p-0 pb-2 pb-md-0 pe-0 pe-md-2"}>
                        <SchoolBuildingImages images={data.Bilder.data}/>
                    </div>
                }
                <div className={`col-12 ${data?.Bilder?.data && "col-md-6"} p-0 pt-2 pt-md-0 ps-0 ps-md-2`}>
                    <Location mapsEmbedLink={data?.MapsEmbedLink} minHeight={!data?.Bilder?.data && 400}/>
                </div>
            </div>
            {!noImprint && <Imprint/>}
        </div>
    );
}

export const Links = ({data}) => {
    return data && (
        <div className="mb-5">
            <div className="row bg-light rounded-4 py-5 px-2 px-md-5">
                <div className="col d-flex align-items-center mb-5 my-lg-0">
                    <div className="btn-icon shadow bg-primary me-4">
                        <FaIcons.FaMapMarkerAlt className={"text-white fs-2"}/>
                    </div>
                    <div className="mt-n1">
                        <h4>Adresse</h4>
                        <p className="m-0">
                            <a
                                className={"text-black"}
                                target={"_blank"}
                                href={data.Adresse.Link}
                            >
                                {data.Adresse.Adresse}
                            </a>
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-center mb-5 my-lg-0">
                    <div className="btn-icon shadow bg-secondary me-4">
                        <FaIcons.FaPhoneAlt className={"text-white fs-2"}/>
                    </div>
                    <div className="mt-n1">
                        <h4>Telefon</h4>
                        <p className="m-0">
                            <a className={"text-black"} href={`tel:${data.Telefonnummer}`}>{data.Telefonnummer}</a>
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-center">
                    <div className="btn-icon shadow bg-warning me-4">
                        <FaIcons.FaEnvelope className={"text-white fs-2"}/>
                    </div>
                    <div className="mt-n1">
                        <h4>Email</h4>
                        <p className="m-0">
                            <a className={"text-black"}
                               href={`mailto:${data.Email}`}>{data.Email}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const SchoolBuildingImages = ({images}) => {
    return images && (
        <div id={"school-building-images-carousel"} className="carousel slide carousel-fade" data-bs-ride="true">
            <div className="carousel-inner">
                {images.map((image, idx) => {
                    return (
                        <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                            <img
                                src={process.env.REACT_APP_BASE_URL + image.attributes.url}
                                className={`d-block w-100 rounded-4`}
                                alt={image.attributes.alternativeText}
                            />
                        </div>
                    );
                })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#school-building-images-carousel"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#school-building-images-carousel"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export const Form = () => {
    return (
        <div className="align-items-center p-5">
            <div className="">
                <div className="section-title position-relative mb-4">
                    <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">Noch
                        Fragen?</h6>
                    <h1 className="display-5 fw-bold">Schreib uns eine Nachricht</h1>
                </div>
                <div className="contact-form">
                    <form>
                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text"
                                       className="form-control border-top-0 border-end-0 border-start-0"
                                       placeholder="Dein Name" required="required"/>
                            </div>
                            <div className="col-6 mb-2">
                                <input type="email"
                                       className="form-control border-top-0 border-end-0 border-start-0"
                                       placeholder="Deine Email" required="required"/>
                            </div>
                        </div>
                        <div className="mb-2">
                            <input type="text"
                                   className="form-control border-top-0 border-end-0 border-start-0"
                                   placeholder="Titel" required="required"/>
                        </div>
                        <div className="mb-2">
                                    <textarea className="form-control border-top-0 border-end-0 border-start-0"
                                              rows="5" placeholder="Nachricht" required="required"></textarea>
                        </div>
                        <div className={"pt-2"}>
                            <button className="btn btn-primary text-white py-3 px-5 rounded-3 shadow"
                                    type="submit">Nachricht
                                senden
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export const Location = ({mapsEmbedLink, minHeight}) => {
    return mapsEmbedLink && (
        <div className={"h-100 w-100"}>
            <iframe
                className={"rounded-4 shadow-sm h-100 w-100"}
                src={mapsEmbedLink}
                allowfullscreen
                loading="lazy"
                style={{minHeight: minHeight}}
            ></iframe>
        </div>
    );
}

const Imprint = () => {
    const {data} = useContent("impressum");

    return data && (
        <div className={"pt-10"} id={"imprint"}>
            <div className="section-title mb-3">
                <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2 fs-1_25em">
                    {data.Titel}
                </h6>
            </div>
            <ReactMarkdown components={{a: MDLinkRenderer}} className={"text-break"} children={data.Text}/>
        </div>
    );
}