import './Courses.css';
import ReactMarkdown from "react-markdown";
import {useContent} from "../../../context/ContentProvider";
import {FaInfoCircle} from "react-icons/fa";
import {MDLinkRenderer} from "../../../utils/utils";

export default function Courses() {
    const {data: courses} = useContent("courses");
    const {data: infoText} = useContent("coursesInfoText");

    return courses && (
        <div className={"mt-5"}>
            {
                infoText?.Text &&
                <div className={"container mb-5 lead"}>
                    <FaInfoCircle className={"text-primary me-2"}/>
                    <span>{infoText.Text}</span>
                </div>
            }
            <div className="container">
                <div className="row gap-4 mx-4 justify-content-center">
                    {
                        courses.map((course, idx) => {
                            return (
                                <div key={idx} className={"col course-item shadow p-0"}
                                     style={{animationDelay: `calc(${idx} * 150ms)`}}>
                                    {course?.attributes?.image?.data &&
                                        <img alt="ADS" className={"img-fluid"}
                                             src={process.env.REACT_APP_BASE_URL + course?.attributes?.image?.data?.attributes.url}
                                        />
                                    }
                                    <div className={"p-2 px-4"}>
                                        <h1 className={"lead fs-2 text-center py-2"}>{course.attributes.name}</h1>
                                        <ReactMarkdown
                                            components={{a: MDLinkRenderer}}
                                            children={course.attributes.description}
                                            className={"text-center lead fs-6 text-break"}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}