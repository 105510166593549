import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContent } from "../../context/ContentProvider";
import { MDLinkRenderer } from "../../utils/utils";
import ReactMarkdown from "react-markdown";

const alertColors = {
  blau: "primary",
  hellgrau: "secondary",
  grün: "success",
  rot: "danger",
  gelb: "warning",
  türkis: "info",
  dunkelgrau: "dark",
};

const Navbar = () => {
  const { data } = useContent("assets");
  const [navbarClosed, setNavbarClosed] = useState(true);
  const [navHeight, setNavHeight] = useState(true);
  const navRef = useRef();
  const [navClicked, setNavClicked] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setNavbarClosed(!navbarClosed);
  };

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      if (navClicked) {
        setNavClicked(false);
        return;
      }

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setNavHeight(navRef.current.clientHeight);
      } else {
        setNavHeight(null);
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navRef, navClicked]);

  const navContStyle = useMemo(() => {
    return {
      top: navHeight ? `-${navHeight}px` : "0px",
    };
  }, [navHeight]);

  useEffect(() => {
    const shownDropdowns = document.getElementsByClassName("dropdown-menu show");
    for (let i = 0; i < shownDropdowns.length; i++) {
      shownDropdowns[i].classList.remove("show");
    }

    // Scroll to top on location change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      ref={navRef}
      className={`bg-white sticky-top nav-transition-top`}
      style={{ ...navContStyle, zIndex: 1050 }}
      onClick={() => setNavClicked(true)}
    >
      {data?.Meldung && (
        <div className={`alert alert-${alertColors[data.Meldung.Farbe]} text-center pb-0`} role="alert">
          <ReactMarkdown
            components={{ a: MDLinkRenderer }}
            children={data.Meldung.Text}
            className={"text-break"}
          />
        </div>
      )}

      <nav className={`container navbar navbar-expand-lg`}>
        <div className="container-fluid d-flex align-items-center">
          <a className="navbar-brand me-5" href="/">
            <img
              style={{ maxHeight: "120px" }}
              src={process.env.REACT_APP_BASE_URL + data?.Logo?.data?.attributes.url}
              alt="MS-Hausleiten"
            />
          </a>
          <button className="navbar-toggler" type="button" onClick={handleToggle}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse mt-auto ${navbarClosed ? "" : "show"}`} id="navbarNavDropdown">
            <ul className="navbar-nav column-gap-xl-5 column-gap-3 text-uppercase">
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarClosed(true)}
                  to={"/"}
                  className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
                >
                  Startseite
                </Link>
              </li>
              <li className="nav-item dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  data-bs-toggle="dropdown"
                  className={`nav-link dropdown-toggle ${
                    ["/events", "/dates"].includes(location.pathname) ? "active" : ""
                  }`}
                >
                  Aktuelles
                </a>
                <ul className="dropdown-menu py-0">
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/events"}
                      className={`dropdown-item ${location.pathname === "/events" ? "active" : ""}`}
                    >
                      Beiträge
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/dates"}
                      className={`dropdown-item ${location.pathname === "/dates" ? "active" : ""}`}
                    >
                      Termine
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/downloads"}
                      className={`dropdown-item ${location.pathname === "/downloads" ? "active" : ""}`}
                    >
                      Downloads
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  data-bs-toggle="dropdown"
                  className={`nav-link dropdown-toggle ${
                    ["/courses", "/projects", "/additional-offers"].includes(location.pathname) ? "active" : ""
                  }`}
                >
                  Angebote
                </a>
                <ul className="dropdown-menu py-0">
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/courses"}
                      className={`dropdown-item ${location.pathname === "/courses" ? "active" : ""}`}
                    >
                      Unverbindliche Übungen
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/projects"}
                      className={`dropdown-item ${location.pathname === "/projects" ? "active" : ""}`}
                    >
                      Schulveranstaltungen
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/additional-offers"}
                      className={`dropdown-item ${location.pathname === "/additional-offers" ? "active" : ""}`}
                    >
                      Zusatzangebote
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  data-bs-toggle="dropdown"
                  className={`nav-link dropdown-toggle ${
                    ["/team", "/classes", "/parent-association", "/partner-school", "/buffet"].includes(
                      location.pathname
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  Schule
                </a>
                <ul className="dropdown-menu py-0">
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/team"}
                      className={`dropdown-item ${location.pathname === "/team" ? "active" : ""}`}
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/classes"}
                      className={`dropdown-item ${location.pathname === "/classes" ? "active" : ""}`}
                    >
                      Klassen
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/parent-association"}
                      className={`dropdown-item ${location.pathname === "/parent-association" ? "active" : ""}`}
                    >
                      Elternverein
                    </Link>
                  </li>
                  {data?.Hausordnung?.data?.attributes.url && (
                    <li>
                      <a
                        rel="noreferrer"
                        href={process.env.REACT_APP_BASE_URL + data.Hausordnung.data.attributes.url}
                        target={"_blank"}
                        className="dropdown-item"
                      >
                        Hausordnung
                      </a>
                    </li>
                  )}
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/partner-school"}
                      className={`dropdown-item ${location.pathname === "/partner-school" ? "active" : ""}`}
                    >
                      Partnerschulen
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setNavbarClosed(true)}
                      to={"/buffet"}
                      className={`dropdown-item ${location.pathname === "/buffet" ? "active" : ""}`}
                    >
                      Buffet
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarClosed(true)}
                  to={"/contact"}
                  className={`nav-link ${location.pathname === "/contact" ? "active" : ""}`}
                >
                  Kontakt
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center">
                <a
                  onClick={() => setNavbarClosed(true)}
                  target={"_blank"}
                  rel={"noreferrer"}
                  href={"http://alt.nmshausleiten.ac.at"}
                  className="nav-link"
                  style={{ fontSize: "0.85rem" }}
                >
                  Zur alten Homepage
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
