import {Accordion} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import {useContent} from "../../../context/ContentProvider";
import {MDLinkRenderer} from "../../../utils/utils";

export default function ParentAssociation() {
    const {data} = useContent("elternverein")

    return data && (
        <div className="container pt-5">
            <div className="row">
                <div className="col-md-6">
                    {data?.Text1?.Titel && <h2 className="text-primary mb-4">{data?.Text1?.Titel}</h2>}
                    {
                        data?.Text1?.Text &&
                        <ReactMarkdown
                            components={{a: MDLinkRenderer}}
                            className={"text-break"}
                            children={data?.Text1?.Text}
                        />
                    }
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-between">
                    <div>
                        {data?.Text2?.Titel && <h2 className="mb-2 mb-md-4 text-primary">{data?.Text2?.Titel}</h2>}
                        {
                            data?.Text2?.Text &&
                            <ReactMarkdown
                                components={{a: MDLinkRenderer}}
                                className={"text-break"}
                                children={data?.Text2?.Text}
                            />
                        }
                    </div>
                    <div className={"pt-3"}>
                        {data?.Text3?.Titel && <h2 className="mb-2 mb-md-4 text-primary">{data?.Text3?.Titel}</h2>}
                        {
                            data?.Text3?.Text &&
                            <ReactMarkdown
                                children={data?.Text3?.Text}
                                components={{a: MDLinkRenderer}}
                                className={"text-break"}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className={"row mt-5"}>
                <div className="col-12 col-md-4">
                    {data?.Text4?.Titel && <h4 className="text-primary mb-4">{data?.Text4?.Titel}</h4>}
                    {data?.Text4?.Text &&
                        <ReactMarkdown
                            children={data?.Text4?.Text}
                            components={{a: MDLinkRenderer}}
                            className={"text-break"}
                        />
                    }
                </div>
                <div className="col-12 col-md-8">
                    {data?.image?.data?.attributes.url ?
                        <img className={"w-100"}
                             src={process.env.REACT_APP_BASE_URL + data?.image?.data?.attributes.url}
                             alt="Elternverein"/>
                        : ""}
                </div>
            </div>

            <hr className={"mt-5"}/>

            <Accordion className={"mt-5"}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Auszug aus den "STATUTEN des ELTERNVEREINES der MITTELSCHULE
                        HAUSLEITEN"</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <p><strong>§1 NAME UND SITZ DES ELTERNVEREINES</strong></p>
                            <p>Der Verein führt den Namen „Elternverein der Mittelschule Hausleiten“ und hat seinen
                                Sitz in Hausleiten.</p>
                            <p><strong>§2 ZWECK DES ELTERNVEREINES</strong></p>
                            <ol>
                                <li>Der Elternverein, dessen Tätigkeit gemeinnützig und nicht auf Gewinn
                                    ausgerichtet ist, hat die Aufgabe, die Interessen der Vereinsmitglieder an der
                                    Unterrichts- und Erziehungsarbeit der Schule zu vertreten und die notwendige
                                    Zusammenarbeit von Eltern und Schule zu unterstützen, insbesondere:
                                    <ol>
                                        <li>Die Wahrnehmung aller dem
                                            Elternverein gemäß den Bestimmungen des Schulunterrichtsgesetzes
                                            zustehenden Rechte,
                                        </li>
                                        <li>Die Unterstützung der
                                            Erziehungsberechtigten bei der Geltendmachung der Ihnen nach dem
                                            Schulunterrichtsgesetz zustehenden Rechte,
                                        </li>
                                        <li>In steter Fühlung und gemeinsamer
                                            Arbeit mit der Schulleitung, den Lehrern und den Elternvertretern des
                                            Schulforums der Schule den Unterricht und die Erziehung der Kinder in
                                            jeder geeigneten Weise zu fördern,
                                        </li>
                                        <li>Das Verständnis der Eltern für die
                                            von der Schule durchgeführte und zu leistende Erziehungsarbeit zu
                                            vertiefen,
                                        </li>
                                        <li>Die erzieherischen Maßnahmen des
                                            Elternhauses mit denen der Schule abzustimmen,
                                        </li>
                                        <li>Gelegentlich bei der
                                            Fürsorgetätigkeit zu Gunsten bedürftiger der Schule mitzuwirken,
                                        </li>
                                        <li>Über den unmittelbaren
                                            Schulbereich hinausgehende Interessen der Kinder (Sicherung von
                                            Schulwegen, Umgebung, Freizeitmöglichkeiten…) zu unterstützen.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <ol>
                                <li>Diese Aufgabe soll unter anderem erreicht werden durch:
                                    <ol>
                                        <li>Vortrag von Vorschlägen, Wünschen
                                            und Beschwerden über die Unterrichts- und Erziehungsarbeit der Schule,
                                        </li>
                                        <li>Abhaltung von Zusammentreffen der
                                            Vereinsmitglieder mit der Schule zur gemeinsamen Beratung von Fragen im
                                            Sinne des Absatz 1,
                                        </li>
                                        <li>Abhaltung von Vorträgen bildender
                                            Art im Sinne des Absatz 1, wobei als Vortragende Schulleiter, Lehrkräfte
                                            der Schule, die im Referentenverzeichnis des zuständigen
                                            Landesschulrates enthaltenen Referenten/-innen, Vertreter/-innen der
                                            Elternvereinsorganisationen (Landesverbände, Dachverband) in Betracht
                                            kommen,
                                        </li>
                                        <li>Abhaltung von musikalischen,
                                            künstlerischen und sonstigen Veranstaltungen, welche den unter Absatz 1
                                            angegebenen Vereinszweck fördern und die im Sinne der gesetzlichen
                                            Bestimmungen anzumelden sind,
                                        </li>
                                        <li>Veranstaltungen von
                                            Schüleraufführungen, Sportveranstaltungen, und ähnlichem, unter
                                            Beachtung der gesetzlichen Vorschriften (schulbehördliche Bewilligung),
                                        </li>
                                        <li>Ausgestaltung der für Unterrichts-
                                            und Erziehungszwecke verfügbaren Einrichtungen der Schule im
                                            Einvernehmen mit der Schulleitung und den Lehrern und
                                            erforderlichenfalls mit der zuständigen Schulbehörde.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <ol>
                                <li>Die Tätigkeit des Elternvereines umfasst nicht:
                                    <ol>
                                        <li>Die Ausübung schulbehördlicher
                                            Befugnisse (Aufsichtsrecht über die Lehrpersonen, Einmischung in
                                            Amtshandlungen, usw.),
                                        </li>
                                        <li>Die Erörterung parteipolitischer
                                            Angelegenheiten,
                                        </li>
                                        <li>Jede regelmäßige
                                            Fürsorgetätigkeit.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p><strong>§3 MITGLIEDSCHAFT</strong></p>
                            <ol>
                                <li>Mitglieder des Elternvereins können <span
                                    style={{textDecoration: "underline"}}>nur</span> Erziehungsberechtigte der
                                    Kinder
                                    sin, welche die Schule besuchen. Für den Begriff des Erziehungsberechtigten sind
                                    die Bestimmungen des SCHUG sinngemäß anzuwenden. Steht das Erziehungsrecht
                                    mehreren Personen zu, so haben sie nur ein Stimmrecht. Der Mitgliedsbeitrag ist
                                    nur einmal zu bezahlen.
                                </li>
                                <li>Die Mitgliedschaft erlischt durch Austritt, jedenfalls aber wenn das Kind aus
                                    der Schule ausscheidet.
                                </li>
                                <li>Mitglieder, welche mit ihren Mitgliedsbeiträgen durch mehr als vier Monate nach
                                    Vorschreibung trotz Mahnung im Rückstand sind oder durch ihr Verhalten den
                                    Vereinszweck schädigen, können mit Beschluss der Hauptversammlung ausgeschlossen
                                    werden.
                                </li>
                            </ol>
                            <p><strong>§4 RECHTE UND PFLICHTEN DER MITGLIEDER DES ELTERNVEREINES</strong></p>
                            <ol>
                                <li>Die Vereinsmitglieder haben die ihnen in diesem Status eingeräumte Rechte und
                                    auferlegten Pflichten. Sie haben insbesondere den Vereinszweck (§2) in jeder
                                    Weise zu fördern.
                                </li>
                                <li>Die Vereinsmitglieder haben das Recht, an allen Versammlungen und
                                    Veranstaltungen des Vereines mit beratender und beschließender Stimme
                                    teilzunehmen.
                                </li>
                                <li>Sie haben das aktive und passive Wahlrecht.</li>
                                <li>Lehrer, deren Kinder die im §1 genannte Schule besuchen, haben die gleichen
                                    Rechte wie die übrigen Vereinsmitglieder.
                                </li>
                                <li>Die Vereinsmitglieder sind zur pünktlichen Bezahlung des Mitgliedsbeitrages
                                    verpflichtet.
                                </li>
                            </ol>
                            <p>…</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}