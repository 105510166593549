import { FaFileDownload } from "react-icons/fa";
import { useContent } from "../../../context/ContentProvider";
import { MDLinkRenderer } from "../../../utils/utils";
import ReactMarkdown from "react-markdown";

export default function Downloads() {
  const { data, loading } = useContent("downloads");

  return (
    <div className="container">
      <div className="my-5 mx-2 row gap-5">
        {!loading && (!data || data.length === 0) && (
          <div className="text-center">
            <p className="lead">Aktuell sind keine Downloads verfügbar.</p>
          </div>
        )}
        {data?.map((item, index) => (
          <div key={index} className="col">
            <DownloadCard data={item?.attributes} />
          </div>
        ))}
      </div>
    </div>
  );
}

const DownloadCard = ({ data }) => {
  return (
    <div
      className="bg-light rounded rounded-4 p-3 h-100 d-flex flex-column justify-content-between"
      style={{ minWidth: 275 }}
    >
      <div style={{ position: "relative", top: -25, left: -30 }}>
        <div className="bg-warning rounded rounded-3 p-2 px-3 fs-3 fw-bold text-white d-inline">{data?.Dateityp}</div>
      </div>
      <div className="flex-grow-1">
        <h1 className="lead fs-2">{data?.Titel}</h1>
        {data?.Beschreibung && (
          <ReactMarkdown
            components={{ a: MDLinkRenderer }}
            className="text-break mb-4 pb-2"
            children={data?.Beschreibung}
          />
        )}
      </div>
      <div className="d-flex justify-content-end">
        <a
          href={process.env.REACT_APP_BASE_URL + data?.Datei?.data?.attributes?.url}
          target="_blank"
          rel="noreferrer"
          style={{ width: 70, height: 70 }}
          className="btn btn-primary text-white rounded rounded-circle fs-2"
        >
          <FaFileDownload className="m-0" />
        </a>
      </div>
    </div>
  );
};
