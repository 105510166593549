import "./Home.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {useEffect, useRef, useState} from "react";
import "animate.css";
import "./Events.css";
import {Link} from "react-router-dom";
import {Links, Location, SchoolBuildingImages} from "../contact/Contact";
import ReactMarkdown from "react-markdown";
import {useContent} from "../../context/ContentProvider";
import {MDLinkRenderer} from "../../utils/utils";

export default function Home() {
    return (
        <div
            // style={{
            //     backgroundImage: `url(/img/school-items.png)`,
            //     backgroundAttachment: "fixed",
            //     backgroundRepeat: "no-repeat",
            //     backgroundSize: "cover"
            // }}
        >
            <Banner/>
            <EventsCarousel/>
            <Leitbild/>
            <IntroVideo/>
            <Signs/>
            <ContactSection/>
        </div>
    );
}

const Banner = () => {
    const {data} = useContent("assets");

    return (
        <>
            <div id={"banner"} style={{
                position: "relative",
                height: "720px",
                zIndex: 0,
                backgroundImage: `url(${process.env.REACT_APP_BASE_URL + data?.Banner?.data?.attributes.url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover"
            }}>
                <div style={{
                    height: "720px",
                    backgroundImage: `url(/img/overlay-bottom.png)`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "bottom center",
                    zIndex: 1
                }}></div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <h1 className="display-1 mb-0 text-white text-shadow-black fw-500 fst-italic">
                        Mittelschule Hausleiten
                    </h1>
                    <h2 className="display-4 text-white text-shadow-black">Herzlich Willkommen</h2>
                </div>
            </div>
        </>
    );
}

const Leitbild = () => {
    const {data} = useContent("leitbild");

    const getRotatingBg = (index) => {
        const bg = [
            "bg-success",
            "bg-primary",
            "bg-warning",
            "bg-danger",
            "bg-info",
        ];
        return bg[index % bg.length];
    }

    return data && (
        <div className="bg-white shadow-lg py-10">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 mb-5 mb-xxl-0 order-1 order-xxl-0 mt-5 mt-xxl-0"
                             >
                            <div className="h-100 shadow-lg d-flex align-items-center">
                                <img
                                    className="img-fluid "
                                    src={process.env.REACT_APP_BASE_URL + data?.Bild?.data?.attributes.url}
                                    alt={process.env.REACT_APP_BASE_URL + data?.Bild?.data?.attributes.alternativeText}
                                    style={{objectFit: "cover"}}
                                />
                            </div>
                        </div>
                        <div className="col-xxl-7 d-flex flex-column justify-content-between order-0 order-xxl-1">
                            <div>
                                <div className="section-title position-relative mb-4">
                                    <h6 className="d-inline-block position-relative text-danger text-uppercase pb-2">Leitbild</h6>
                                    <h1 className="display-4 fw-bold">{data?.Titel}</h1>
                                </div>
                                <ReactMarkdown components={{a: MDLinkRenderer}} className={"text-break"} children={data?.Text}/>
                            </div>
                            <div className="row mx-0 flex-sm-shrink-1 d-flex" style={{flex: 1}}>
                                {data?.Zahlenfakt?.length && data.Zahlenfakt.map((fakt, index) => {
                                    return (
                                        <div key={index} className="col d-flex px-0">
                                            <div
                                                className={`${getRotatingBg(index)} text-center p-4 flex-fill d-flex flex-column justify-content-center`}>
                                                <h1 className="text-white" data-toggle="counter-up">
                                                    <CountUp useEasing={true} end={fakt.Zahl} redraw={true}>
                                                        {({countUpRef, start}) => (
                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                <span ref={countUpRef}/>
                                                            </VisibilitySensor>
                                                        )}
                                                    </CountUp>
                                                </h1>
                                                <h6 className="text-uppercase text-white">{fakt.Fakt}</h6>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const IntroVideo = () => {
    const {data} = useContent("video");

    const VideoPlayer = () => {
        return data && (
            <div className={"ratio ratio-16x9"} style={{position: 'relative'}}>
                <iframe
                    id={"introVideo"}
                    src={`https://www.youtube.com/embed/${data.Videoschluessel}`}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
        );
    };

    return data && (
        <div className={"bg-primary py-5"}>
            <div className={"container my-5"}>
                <div className="row">
                    <div className="col-xxl-4 col-12 d-flex align-items-center mb-2 mb-xxl-0">
                        <h2 className={"display-3 text-white fw-600 fst-italic flex-wrap text-wrap"}>
                            {data.Titel}
                        </h2>
                    </div>
                    <div className="col-xxl-8 col-12">
                        <VideoPlayer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Signs = () => {
    const {data} = useContent("signs");

    return data && (
        <div className={"container-fluid bg-white py-5"}>
            <div className="container section-title position-relative py-5">
                <h6 className="d-inline-block position-relative text-danger text-uppercase pb-2">Gütesiegel</h6>
                <h1 className="display-4 fw-bold">Unsere Auszeichnungen</h1>
            </div>
            <div className={"row gap-5em align-items-center justify-content-center pb-5"}>
                {data.map((sign, index) => {
                    return (
                        <div key={index} className={"col"} style={{maxWidth: 300, minWidth: 200}}>
                            <img className={"img-fluid w-100"}
                                 src={process.env.REACT_APP_BASE_URL + sign.attributes.Siegel.data.attributes.url}
                                 alt={sign.attributes.Siegel.data.attributes.alternativeText}/>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const EventsCarousel = () => {
    const [events, setEvents] = useState([]);
    const [loadedImages, setLoadedImages] = useState(0);
    const eventSliderRef = useRef(null);

    // fetch all events
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/events?pagination[pageSize]=10&populate=*&sort=date:DESC`)
            .then(response => response.json())
            .then(data => {
                if (data.data) {
                    if (data.data.length < 10) {
                        const filledEvents = data.data;
                        let i = 0;
                        while (filledEvents.length < 10) {
                            filledEvents.push(data.data[i]);
                            i++;
                            if (i === data.data.length) i = 0;
                        }
                        setEvents(filledEvents);
                    } else {
                        setEvents(data.data)
                    }
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
        if (events.length === 0 || loadedImages < events.length) return;

        const sliderWidth = eventSliderRef.current?.scrollWidth / 2 || 0;
        const cssAnimation = document.createElement('style');
        const rules = document.createTextNode(
            `
            @keyframes slide {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-${sliderWidth}px);
                }
            }
            #eventSlider {
                animation: slide 70s linear infinite;
            }
            `
        );
        cssAnimation.appendChild(rules);
        document.getElementById("eventSlider").appendChild(cssAnimation);
    }, [events, loadedImages]);

    const handleImageLoad = () => {
        setLoadedImages(prev => prev + 1);
    }

    return (
        <div className={"bg-white overflow-hidden py-5"}>
            <div style={{paddingBlock: "5em"}}>
                <div ref={eventSliderRef} id="eventSlider" className={"d-flex align-items-center"}>
                    {[...events, ...events].map((event, index) => {
                        if (!event.attributes.images.data) return null;
                        return (
                            <div key={index} className="event-container m-2">
                                <img height={400} className={"shadow"}
                                     src={process.env.REACT_APP_BASE_URL + event.attributes.images.data[0].attributes.url}
                                     alt={event.attributes.title}
                                     onLoad={handleImageLoad}/>
                                <div className="event-title">
                                    <span className={"text-center"}>{event.attributes.title}</span>
                                </div>
                            </div>
                        );
                    })}

                </div>
                <div className={"text-center"}>
                    <Link to={"/events"} className={"text-body"} style={{letterSpacing: "1px"}}>Alle Events
                        anzeigen</Link>
                </div>
            </div>
        </div>
    );
}

const ContactSection = () => {
    const {data} = useContent("contact");

    return data && (
        <div className={"bg-white pt-5"}>
            <div className={"bg-light"}>
                <div className={"container"}>
                    <Links data={data}/>
                </div>
            </div>
            <div className={"container py-5"}>
                <div className="row">
                    {data?.Bilder?.data &&
                        <div className={"col-12 col-md-6 p-0 pb-2 pb-md-0 pe-0 pe-md-2"}>
                            <SchoolBuildingImages images={data.Bilder.data}/>
                        </div>
                    }
                    <div className={`col-12 ${data?.Bilder?.data && "col-md-6"} p-0 pt-2 pt-md-0 ps-0 ps-md-2`}>
                        <Location mapsEmbedLink={data?.MapsEmbedLink} minHeight={!data?.Bilder?.data && 400}/>
                    </div>
                </div>
            </div>
        </div>
    );
}