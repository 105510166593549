import {useEffect} from "react";
import "../projects/Projects.css";
import ReactMarkdown from "react-markdown";
import {ScrollSpy} from "bootstrap";
import {BsRecord, BsRecord2} from "react-icons/bs";
import {useContent} from "../../../context/ContentProvider";
import {MDLinkRenderer} from "../../../utils/utils";

export default function AdditionalOffers() {
    const {data: offers} = useContent("offers");

    useEffect(() => {
        // Initialize Bootstrap ScrollSpy
        const scrollSpy = new ScrollSpy(document.body, {
            target: '#projects-list'
        });

        return () => {
            scrollSpy.dispose();
        };
    }, [offers]);

    return offers && (
        <div className="container-fluid container-xl">
            <div className="row">
                <div className="col-1 col-xl-3">
                    <div id={"projects-list"} className="sticky-top d-flex flex-column gap-3 sticky-scrollspy-nav">
                        {offers?.map((project, idx) => (
                            <a key={idx}
                               className={"btn btn-link px-0 project-list-ref text-dark text-decoration-none text-start d-flex align-items-center"}
                               href={`#project-list-item-${idx}`}>
                                <span className={"me-2"}>
                                    <BsRecord className={"icon-inactive"} size={20}/>
                                    <BsRecord2 className={"icon-active d-none text-warning"} size={20}/>
                                </span>
                                <span
                                    className={"li-title rounded-end-5 rounded-start-3 p-2 pe-3 d-none d-xl-inline-block text-break"}
                                >{project.attributes.title}</span>
                            </a>
                        ))}
                    </div>
                </div>
                <div id={"projects-list-items"} className="col-10 col-md-11 col-xl-9">
                    {offers?.map((project, idx) => (
                        <div key={idx} id={`project-list-item-${idx}`}
                             className="project-item row my-4 my-md-5 py-4 py-md-5">
                            <div className={`${project.attributes.images.data ? "col-12 col-md-6" : "col"}`}>
                                <h1 className={"display-5 text-primary-emphasis text-break"}>{project.attributes.title}</h1>
                                <ReactMarkdown components={{a: MDLinkRenderer}} className={"text-break"}>{project.attributes.description}</ReactMarkdown>
                            </div>
                            {project.attributes.images.data &&
                                <div className={"col-12 col-md-6 d-flex align-items-center"}>
                                    <div id={`projectCarouselIndicators-${project.id}`}
                                         className="carousel slide w-100">
                                        {project.attributes.images.data?.length > 1 &&
                                            <div className="carousel-indicators">
                                                {project.attributes.images.data?.map((image, idx) => (
                                                    <button
                                                        key={idx}
                                                        type="button"
                                                        data-bs-target={`#projectCarouselIndicators-${project.id}`}
                                                        data-bs-slide-to={idx}
                                                        className={idx === 0 ? 'active' : ''}
                                                        aria-label={`Slide ${idx + 1}`}
                                                    ></button>
                                                ))}
                                            </div>}
                                        <div className="carousel-inner d-flex align-items-center">
                                            {project.attributes.images.data?.map((image, idx) => {
                                                return (
                                                    <div key={idx}
                                                         className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
                                                        <img src={process.env.REACT_APP_BASE_URL + image.attributes.url}
                                                             className="d-block w-100"
                                                             alt="Projektbild"/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {project.attributes.images?.data?.length > 1 && <>
                                            <button className="carousel-control-prev" type="button"
                                                    data-bs-target={`#projectCarouselIndicators-${project.id}`}
                                                    data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button"
                                                    data-bs-target={`#projectCarouselIndicators-${project.id}`}
                                                    data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </>}
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
