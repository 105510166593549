import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import {useContent} from "../../../context/ContentProvider";
import {MDLinkRenderer} from "../../../utils/utils";

const PartnerSchool = () => {
    const {data} = useContent("partnerschools")

    const [imageIndices, setImageIndices] = useState([]);
    const [isTransitioning, setIsTransitioning] = useState([]);

    useEffect(() => {
        if (!data?.length) return;
        setImageIndices(data.map(() => 0));
        setIsTransitioning(data.map(() => false));
    }, [data]);

    useEffect(() => {
        if (data?.length > 0) {
            const intervalIds = data.map((psData, idx) => {
                if (psData.attributes.Bilder.data.length > 1) {
                    return setInterval(() => {
                        setIsTransitioning(prev => {
                            const newTransitioning = [...prev];
                            newTransitioning[idx] = true;
                            return newTransitioning;
                        });
                        setTimeout(() => {
                            setImageIndices(prevIndices => {
                                const newIndices = [...prevIndices];
                                newIndices[idx] = (newIndices[idx] + 1) % psData.attributes.Bilder.data.length;
                                return newIndices;
                            });
                            setIsTransitioning(prev => {
                                const newTransitioning = [...prev];
                                newTransitioning[idx] = false;
                                return newTransitioning;
                            });
                        }, 1000);
                    }, 10000);
                }
                return null;
            });

            return () => intervalIds.forEach(id => id && clearInterval(id));
        }
    }, [data]);

    return !!data?.length && (
        <div className={"bg-white"}>
            <div className="pt-5 partnerschool-section d-flex align-items-center">
                <div className="container py-5">
                    {data?.map((psData, idx) => {
                        return (
                            <div key={idx}
                                 className={`row align-items-center ${data.length > 1 && idx !== data.length - 1 && "pb-10"}`}>
                                <div className={`col-lg-5 mb-5 mb-lg-0 order-${idx % 2}`}>
                                    <div className="section-title position-relative mb-4">
                                        <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                                            {psData.attributes.Titel1}
                                        </h6>
                                        <h1 className="display-4 fw-bold">
                                            {psData.attributes.Titel2}
                                        </h1>
                                    </div>
                                    <ReactMarkdown
                                        components={{a: MDLinkRenderer}}
                                        className="m-0 text-break"
                                        children={psData.attributes.Text}
                                    />
                                </div>
                                {
                                    psData?.attributes?.Bilder?.data && (
                                        <div className="col-lg-7 d-flex justify-content-center align-items-center">
                                            <div
                                                className={`image-container ${isTransitioning[idx] ? 'transitioning' : ''}`}>
                                                <img
                                                    alt={"clara-fey-schule"}
                                                    src={process.env.REACT_APP_BASE_URL + psData.attributes.Bilder.data[imageIndices[idx]]?.attributes.url}
                                                    className={"w-100 shadow1 rounded-1"}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default PartnerSchool;